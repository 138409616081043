<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" v-validate="{ required: true }"  id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub name is required</div>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2" v-if="products.length > 0">
          <div class="col-md-12 float-right">
            <div class="float-right">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered" id="product_hub_stock_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Product Category</th>
              <th>Current Stock</th>
              <th>Threshold Limit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, index) in products" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.category.title }}</td>
              <td>{{ product.hub_product_stock ? product.hub_product_stock.stock_amount : 0 }}</td>
              <td>{{ product.hub_threshold_limit }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="products.length === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      products: [],
      loading: false,
      btnLoading: false,
      flag: false,
      show: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        hub_id: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/product-stock/hub', this.search_data)
            .then(response => {
              this.btnLoading = false
              this.loading = false
              this.flag = true
              this.products = response.data.products
              setTimeout(function() {
                $('#product_hub_stock_list').DataTable(
                  {
                    bRetrieve: true,
                    pagingType: 'full_numbers',
                    pageLength: 10,
                    processing: true,
                    dom: 'Blfrtip',
                    buttons: ['copy', 'csv', 'print'],
                  },
                )
              }, 3000,
              )
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    excelDownload(index) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.excelDownloadLoader = true
          apiClient.post('api/report/product-stock/hub/download', this.search_data, { responseType: 'blob' }).then(response => {
            this.excelDownloadLoader = false
            if (response.data.size === 68) {
              this.$notification.error({
                message: 'No data found, in this search query',
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'hub_product_stock.xlsx')
              document.body.appendChild(link)
              link.click()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
  },
}
</script>
